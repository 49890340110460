import React from 'react';
import TemporaryPage from './components/Main'


function App() {
  return (
    <div className="App">
      <TemporaryPage />
    </div>
  );
}

export default App;

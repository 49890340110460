import React from "react";
import styled from 'styled-components'

function TemporaryPage() {
  return (
    <PageWrap>
      <img src="/assets/logo.png" alt="logo"/>
      <header>
        <h3>Homepage 준비중입니다.</h3>
      </header>
    </PageWrap>
  )
}

export default TemporaryPage;

const PageWrap = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  img {
    flex: 0 1 auto;
    max-width: 500px;
    overflow: hidden;
  }
`